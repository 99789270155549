import React from "react";

import { getThemeProps, styled } from "style";

import BlockContent from "components/BlockContent";
import Box from "components/Box";
import Image from "components/Image";

const defaultProps = {
  as: "article",
};

const Heading = styled("h3")({
  mb: 3,
  px: 4,
  variant: "text.h3",
});

const style = {
  container: {
    position: "relative",
    mb: 3,
    py: 3,
    bg: "white",
    borderRadius: "default",
    overflow: "hidden",
    boxShadow: "lg",
  },
  heading: {
    px: 4,
    mt: 0,
  },
  eyebrow: {
    px: 4,
    fontSize: 1,
    color: "gray.500",
  },
  footer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    px: 4,
    fontSize: 0,
  },
  image: {
    mx: 3,
    mb: 3,
  },
  body: {
    px: 4,
    mb: 4,
  },
};

const themeProps = getThemeProps({
  style,
  themeKey: "cards",
});

const Card = ({ children, ...props }) => (
  <Box {...themeProps("container", props)}>
    {React.Children.map(children, child => {
      if (child) {
        const variant = child.props.variant
          ? [props.variant, child.props.variant]
          : props.variant;

        return React.cloneElement(child, { variant });
      } else {
        return child;
      }
    })}
  </Box>
);

Card.defaultProps = defaultProps;

Card.Body = CardBody;
Card.Eyebrow = CardEyebrow;
Card.Footer = CardFooter;
Card.Heading = CardHeading;
Card.Image = CardImage;

export default Card;

function CardBody({ blocks, ...props }) {
  return blocks ? (
    <BlockContent {...themeProps("body", props)} blocks={blocks} />
  ) : (
    <Box {...themeProps("body", props)} />
  );
}

function CardEyebrow(props) {
  return <Box {...themeProps("eyebrow", props)} />;
}

function CardFooter(props) {
  return <Box {...themeProps("footer", props)} />;
}

function CardHeading(props) {
  return <Heading {...themeProps("heading", props)} />;
}

function CardImage(props) {
  return <Image {...themeProps("image", props)} />;
}
