import React from "react";

import { styled, getThemeProps } from "style";
import { formatPrice } from "utils/formatting";

import Box from "components/Box";
import VisuallyHidden from "components/VisuallyHidden";

const defaultProps = {
  variant: "default",
};

const style = {
  container: {
    display: "inline-flex",
    flexFlow: "row wrap",
    my: 0,
    mx: -1,
    div: {
      display: "inline-block",
      mx: 1,
    },
    "& dt, & dd": {
      m: 0,
      p: 0,
    },
  },

  price: {
    fontWeight: "bold",
  },

  salePrice: {
    fontWeight: "bold",
    color: "darkGreen",
  },

  compareAtPrice: {
    color: "textMuted",
    fontWeight: "regular",
    textDecoration: "line-through",
  },
};

function getCurrencyCode(price) {
  let currencyCode = price && price.currencyCode;
  return <span> {currencyCode || "USD"}</span>;
}

const StyledList = styled("dl", { themeKey: "price" })(style.container);

const Price = ({
  compareAtPrice,
  price,
  showCurrency,
  quantity,
  variant,
  ...props
}) => {
  const isOnSale = !!compareAtPrice;
  const themeProps = getThemeProps({
    style,
    themeKey: "price",
    variant,
  });

  if (isOnSale) {
    return (
      <StyledList {...themeProps(undefined, props)}>
        <div>
          <VisuallyHidden as="dt">Sale Price </VisuallyHidden>
          <Box as="dd" {...themeProps("salePrice")}>
            {formatPrice(price, quantity)}
            {showCurrency && getCurrencyCode(price)}
          </Box>
        </div>
        <div>
          <VisuallyHidden as="dt">List Price </VisuallyHidden>
          <Box as="dd" {...themeProps("compareAtPrice")}>
            {formatPrice(compareAtPrice, quantity)}
            {showCurrency && getCurrencyCode(compareAtPrice)}
          </Box>
        </div>
      </StyledList>
    );
  }

  return (
    <StyledList {...themeProps("container", props)}>
      <div>
        <VisuallyHidden as="dt">Price </VisuallyHidden>
        <Box as="dd" {...themeProps("price")}>
          {formatPrice(price, quantity)}
          {showCurrency && getCurrencyCode(price)}
        </Box>
      </div>
    </StyledList>
  );
};

Price.defaultProps = defaultProps;

export default Price;
