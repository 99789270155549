import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { resolve as urlResolve } from "../utils/url";
import { get } from "../utils/helpers";

const defaults = {
  absolute: false,
};

export default function useGetProductURL() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteGlobal {
        content {
          siteHostname {
            current
          }
          routes {
            productRouteRoot {
              current
            }
          }
        }
      }
    }
  `);

  const hostname = get(data, "sanitySiteGlobal.content.siteHostname.current");
  // prettier-ignore
  const productRoute = get(data, "sanitySiteGlobal.content.routes.productRouteRoot.current");

  const getProductURL = React.useCallback(
    (slug, _options) => {
      const options = { ...defaults, ...(_options || {}) };
      return options.absolute
        ? urlResolve(hostname, productRoute, slug)
        : urlResolve(productRoute, slug);
    },
    [hostname, productRoute]
  );

  return getProductURL;
}
