import React from "react";

import useGetProductURL from "hooks/useGetProductURL";

import Link from "components/Link";
import LinkedData from "components/LinkedData";
import Price from "components/Price";

import Card from "./Card";

const ProductCard = ({ content, _type }) => {
  const { main, shopify } = content;
  const slug = useGetProductURL()(main.slug && main.slug.current);

  // this is bad
  // don't do this
  let eyebrow = null;
  if (shopify) {
    try {
      const data = JSON.parse(shopify.stringifiedProductData);
      eyebrow = data.options
        .filter(el => el.name === "Type")
        .reduce((memo, el) => {
          return memo + ` ${el.values.join(", ")}`;
        }, "");
    } catch (error) {
      //
    }
  }

  return (
    <Card variant="product">
      {_type !== "productThirdParty" && (
        <LinkedData type="product" {...content} />
      )}
      {main.mainImage && <Card.Image width={200} {...main.mainImage} />}
      {eyebrow && <Card.Eyebrow>{eyebrow}</Card.Eyebrow>}
      <Card.Heading>
        <Link to={slug} variant={["cover", "plain"]} title={main.title}>
          {main.title}
        </Link>
      </Card.Heading>
      <Card.Body>{shopify && <Price price={shopify.defaultPrice} />}</Card.Body>
    </Card>
  );
};

export default ProductCard;
