import React from "react";
import { graphql } from "gatsby";

import Box from "components/Box";
import Heading from "components/Heading";
import Image from "components/Image";
import Meta from "components/Meta";
import ProductCard from "components/Card/Product";

const style = {
  productRow: {
    display: "grid",
    px: 4,
    gridGap: 5,
    gridTemplateColumns: [
      "1fr",
      "repeat(2, minmax(0, 1fr))",
      "repeat(3, minmax(0, 1fr))",
    ],
  },
};

const ArtworkTemplate = ({ data }) => {
  const { main, meta } = data.sanityArtwork._rawContent;

  return (
    <Box variant="container">
      <Meta {...meta} title={meta && meta.title ? meta.title : main.title} />
      <Image {...main.image} layout="fullWidth" />
      {main.products && (
        <Box sx={{ my: 5 }}>
          <Heading as="h2">Purchase a print</Heading>
          <Box sx={style.productRow}>
            {main.products.map(product => (
              <ProductCard key={product._id} {...product} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ArtworkTemplate;

export const query = graphql`
  query sanityArtwork($id: String!) {
    sanityArtwork(id: { eq: $id }) {
      _rawContent(resolveReferences: { maxDepth: 9 })
    }
  }
`;
